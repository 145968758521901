import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`
const MovingElement = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    transform: translateY(0);
    transition: .7s ease-out transform;
    will-change: transform;
`

const ImgParallax = ({children, speed = 1}) => {
    const staticImg = useRef(null)
    const movingImg = useRef(null)
    const init = () => {
        const heightWindow = (window.innerHeight + staticImg.current.getBoundingClientRect().height * 3) * -1
        const position = staticImg.current.getBoundingClientRect().y - window.innerHeight/1.1
        position < 0 && position > heightWindow ?
        movingImg.current.style.transform = `translateY(${Math.round(position/(speed + 5.8))}px)` :
        movingImg.current.style.transform = `translateY(0px)`
    }
    useEffect(() => {
        window.addEventListener('scroll', init)
        return () => {
            window.removeEventListener('scroll', init)
        }
    }, [])
    return (
        <Container ref={staticImg}>
            <MovingElement ref={movingImg}>{children}</MovingElement>
        </Container>
    )
}

export default ImgParallax
