import React, { useContext } from "react"
import MediumArticles from "../MediumArticles"
import styled from "styled-components"
import { family, size } from "../../elements/font"
import colors from "../../elements/color"
import { NumberStringHome, NumberMobile, QuoteHome } from "../../elements/Text"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AppContext from "../Context"
import ImgParallax from "../ImgParallax"

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 120px 0 0;
  @media only screen and (max-width: 900px) {
    padding: 50px 0 20px;
  }
`
export const MobileMediumArticles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-right: 3vw;
  margin-top: 30px;
  div:first-of-type {
    width: 58%;
    z-index: 5;
    height: 65px;
    margin: 0 0 50px;
  }
  div:not(:first-of-type) {
    margin: 50px 0;
    width: 43%;
    height: 65px;
  }
`
export const NumbersContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
export const Number = styled.span`
  font-family: ${family.Alata};
  color: ${colors.darkBlue};
  letter-spacing: 0.05em;
  font-size: ${size.L};
  margin: 0 0 20px 0;
  white-space: nowrap;
  @media only screen and (max-width: 900px) {
    white-space: pre-wrap;
    &.nowrap {
      white-space: nowrap;
    }
  }
`
export const Explanation = styled.span`
  font-family: ${family.Alata};
  color: #000;
  letter-spacing: 0.05em;
  text-align: center;
  font-size: ${size.XS};
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`
export const MoreInfos = styled.span`
  position: absolute;
  top: 250%;
  left: 50%;
  width: 120px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: 0.3s ease opacity;
  display: none;
  @media only screen and (max-width: 900px) {
    top: 110%;
    left: 100.5%;
    width: 120px;
    height: 120px;
    background: ${props => (props.color ? props.color : "#0DFF93")};
    border-radius: 50%;
    display: flex;
    z-index: -1;
    align-items: center;
    justify-content: center;
    font-size: ${size.XS};
    font-family: ${family.Alfa};
    text-align: center;
    animation: 8s linear infinite normal forwards moveText;
    @keyframes moveText {
      0% {
        transform: translate(-50%, -50%);
      }
      25% {
        transform: translate(calc(-50% + 20px), -50%);
      }
      50% {
        transform: translate(calc(-50% + 10px), calc(-50% + 10px));
      }
      75% {
        transform: translate(calc(-50% + (-5px)), calc(-50% + 5px));
      }
      100% {
        transform: translate(-50%, -50%);
      }
    }
  }
`
const TextAndImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 90vh;
  margin: 50px 0;
`
const FirstBlock = styled.div`
  width: 40%;
  height: 100%;
  position: relative;
`
const SecondBlock = styled.div`
  width: 60%;
  height: 100%;
  position: relative;
`
const BlockText = styled.p`
  position: absolute;
  max-width: 400px;
  width: 65%;
  min-width: 200px;
  text-align: justify;
  font-family: Space Mono, sans-serif;
  font-size: ${size.XXS};
  line-height: calc(${size.XXS} * 1.6);
  letter-spacing: 0.05em;
  top: 15%;
  left: 30%;
  z-index: 20;
  @media only screen and (max-width: 900px) {
    position: relative;
    width: 85%;
    max-width: 800px;
    left: 20px;
    display: flex;
    margin: 410px 0 0 0;
    flex-direction: column;
    span {
      transform: translateX(-20px);
      font-family: Alata, sans-serif;
      font-size: 10px;
      letter-spacing: 0.05em;
      margin-bottom: 20px !important;
      margin-top: 10px;
    }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1367px) {
    width: 100%;
  }
`
const ImgContainer0 = styled.div`
  position: absolute;
  max-width: 300px;
  width: 45%;
  min-width: 150px;
  left: 36%;
  bottom: 22%;
  z-index: 10;
`
const ImgContainer1 = styled.div`
  position: absolute;
  max-width: 320px;
  width: 65%;
  min-width: 150px;
  left: 0;
  bottom: 0;
  transform: translateX(-20%);
`
const SmallText = styled.p`
  position: absolute;
  width: 40%;
  max-width: 350px;
  min-width: 200px;
  bottom: 8%;
  left: 5%;
  text-align: justify;
  font-family: Space Mono, sans-serif;
  font-size: ${size.XXS};
  line-height: calc(${size.XXS} * 1.6);
  letter-spacing: 0.05em;
  z-index: 20;
  @media only screen and (max-width: 900px) {
    position: relative;
    width: 85%;
    max-width: 800px;
    left: 20px;
    margin-top: 290px;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1366px) {
    width: 100%;
  }
`
const ImgContainer2 = styled.div`
  position: absolute;
  width: 38%;
  max-width: 340px;
  min-width: 150px;
  right: 12%;
  top: 28%;
`
const ImgContainer3 = styled.div`
  position: absolute;
  max-width: 340px;
  width: 33%;
  min-width: 150px;
  right: 0%;
  bottom: 0%;
`
const CatchPhrase = styled(QuoteHome)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px auto 70px;
  max-width: 1200px;
  width: 100%;
  min-width: 300px;
  position: relative;
  z-index: 5;
  @media only screen and (max-width: 900px) {
    padding: 0 9px;
    width: 100%;
  }
`
const MobileTextAndImages = styled.div`
  display: flex;
  flex-direction: column;
`
const UpperBlock = styled.div`
  width: 100%;
  position: relative;
`
const LowerBlock = styled.div`
  width: 100%;
  position: relative;
`
const MobileImg1 = styled.div`
  position: absolute;
  width: 60%;
  left: -10%;
  top: 40%;
  z-index: 1;

  @media screen and (max-width: 900px) and (max-height: 430px) {
    width: 30%;
    left: 0;
  }

  @media screen and (max-width: 800px) {
    width: 45%;
  }
`
const MobileImg2 = styled.div`
  position: absolute;
  width: 53%;
  top: 10%;
  right: 5%;
  z-index: 0;

  @media screen and (max-width: 900px) and (max-height: 430px) {
    width: 23%;
  }

  @media screen and (max-width: 800px) {
    width: 38%;
  }
`
const MobileImg3 = styled.div`
  position: absolute;
  width: 54%;
  top: 11%;
  right: 3%;

  @media screen and (max-width: 900px) and (max-height: 430px) {
    width: 24%;
  }

  @media screen and (max-width: 800px) {
    width: 39%;
  }
`
const MobileImg4 = styled.div`
  position: absolute;
  width: 55%;
  top: 31%;
  left: 0;

  @media screen and (max-width: 900px) and (max-height: 430px) {
    width: 25%;
  }

  @media screen and (max-width: 800px) {
    width: 40%;
  }
`

const ArticlesContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`
const MediumArticlesModified = styled(MediumArticles)`
  transform: translateX(-3.5vw);
  @media only screen and (max-width: 900px) {
    transform: translateX(0vw);
  }
`

const Numbers = () => {
  const { isMobile } = useContext(AppContext)
  const data = useStaticQuery(graphql`
    query {
      prismicIndex {
        data {
          body {
            ... on PrismicIndexBodyChiffresCles {
              items {
                number {
                  text
                }
                number_infos {
                  text
                }
              }
              primary {
                left_image_1 {
                  localFile {
                    childImageSharp {
                      # Specify the image processing specifications right in the query.
                      # Makes it trivial to update as your page's design changes.
                      fluid(maxWidth: 700) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  alt
                }
                left_image_2 {
                  localFile {
                    childImageSharp {
                      # Specify the image processing specifications right in the query.
                      # Makes it trivial to update as your page's design changes.
                      fluid(maxWidth: 700) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  alt
                }
                right_image_1 {
                  localFile {
                    childImageSharp {
                      # Specify the image processing specifications right in the query.
                      # Makes it trivial to update as your page's design changes.
                      fluid(maxWidth: 700) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  alt
                }
                right_image_2 {
                  localFile {
                    childImageSharp {
                      # Specify the image processing specifications right in the query.
                      # Makes it trivial to update as your page's design changes.
                      fluid(maxWidth: 700) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  alt
                }
                numbers_last {
                  text
                }
                numbers_text1 {
                  text
                }
                numbers_text2 {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const prismic = data.prismicIndex.data
  return (
    <Container>
      <NumberStringHome>02.</NumberStringHome>
      <NumberMobile>
        <span>02.</span>
        <span>Chiffres clés</span>
      </NumberMobile>
      {!isMobile && (
        <>
          <svg
            className="bulle-home-soft"
            style={{
              transform: "scale(1.2)",
              position: "absolute",
              left: "-15%",
              top: "50%",
              zIndex: "0",
            }}
            width="841"
            height="818"
            viewBox="0 0 841 818"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-115.887 414.24C-120.9 222.556 41.2659 77.2462 224.506 20.6721C398.765 -33.1295 585.848 19.4172 698.542 162.791C826.987 326.201 902.791 557.291 775.376 721.505C655.08 876.545 429.092 813.141 243.559 749.178C72.3777 690.163 -111.153 595.224 -115.887 414.24Z"
              fill="#F5FAFF"
            />
          </svg>
          <ArticlesContainer>
            <MediumArticlesModified
              green={true}
              articles={[
                {
                  hoverText: "Wow !",
                  title: (
                    <NumbersContainer>
                      <Number>{prismic.body[1].items[0].number.text}</Number>
                      <Explanation>
                        {prismic.body[1].items[0].number_infos.text}
                      </Explanation>
                      <MoreInfos>Wow !</MoreInfos>
                    </NumbersContainer>
                  ),
                },
                {
                  hoverText: "Pourquoi pas la votre ?",
                  title: (
                    <NumbersContainer>
                      <Number>{prismic.body[1].items[1].number.text}</Number>
                      <Explanation>
                        {prismic.body[1].items[1].number_infos.text}
                      </Explanation>
                    </NumbersContainer>
                  ),
                },
                {
                  hoverText: "Ça ne risque pas de changer",
                  title: (
                    <NumbersContainer>
                      <Number>{prismic.body[1].items[2].number.text}</Number>
                      <Explanation>
                        {prismic.body[1].items[2].number_infos.text}
                      </Explanation>
                    </NumbersContainer>
                  ),
                },
                {
                  hoverText: "D’autres arrivent soon",
                  title: (
                    <NumbersContainer>
                      <Number>{prismic.body[1].items[3].number.text}</Number>
                      <Explanation>
                        {prismic.body[1].items[3].number_infos.text}
                      </Explanation>
                    </NumbersContainer>
                  ),
                },
                {
                  hoverText: "On adore ça",
                  title: (
                    <NumbersContainer>
                      <Number>{prismic.body[1].items[4].number.text}</Number>
                      <Explanation>
                        {prismic.body[1].items[4].number_infos.text}
                      </Explanation>
                    </NumbersContainer>
                  ),
                },
              ]}
              circleColor="#FFF9DB"
            />
          </ArticlesContainer>
          <TextAndImages className="changeHeaderString">
            <FirstBlock>
              <BlockText>
                {prismic.body[1].primary.numbers_text1.text}
              </BlockText>
              <ImgContainer0>
                <ImgParallax speed={1}>
                  <Img
                    loading="eager"
                    fluid={
                      prismic.body[1].primary.left_image_2.localFile
                        .childImageSharp.fluid
                    }
                    alt={prismic.body[1].primary.left_image_2.alt}
                  />
                </ImgParallax>
              </ImgContainer0>
              <ImgContainer1>
                <ImgParallax speed={1.7}>
                  <Img
                    loading="eager"
                    fluid={
                      prismic.body[1].primary.left_image_1.localFile
                        .childImageSharp.fluid
                    }
                    alt={prismic.body[1].primary.left_image_1.alt}
                  />
                </ImgParallax>
              </ImgContainer1>
            </FirstBlock>
            <SecondBlock>
              <ImgContainer2>
                <ImgParallax speed={2.8}>
                  <Img
                    loading="eager"
                    fluid={
                      prismic.body[1].primary.right_image_1.localFile
                        .childImageSharp.fluid
                    }
                    alt={prismic.body[1].primary.right_image_1.alt}
                  />
                </ImgParallax>
              </ImgContainer2>
              <ImgContainer3>
                <ImgParallax speed={2}>
                  <Img
                    loading="eager"
                    fluid={
                      prismic.body[1].primary.right_image_2.localFile
                        .childImageSharp.fluid
                    }
                    alt={prismic.body[1].primary.right_image_2.alt}
                  />
                </ImgParallax>
              </ImgContainer3>
              <SmallText>
                {prismic.body[1].primary.numbers_text2.text}
              </SmallText>
            </SecondBlock>
          </TextAndImages>
        </>
      )}
      {isMobile && (
        <>
          <svg
            className="bulle-mobile-droite-soft"
            style={{ position: "absolute", top: "15%", right: "0" }}
            width="233"
            height="384"
            viewBox="0 0 233 384"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.676411 196.278C-1.9291 157.356 2.06106 113.691 29.2672 85.7593C55.6424 58.6808 100.954 71.0987 136.107 57.2363C175.004 41.8974 203.591 -6.82874 244.696 0.806122C285.407 8.36758 296.856 61.221 324.359 92.2017C356.152 128.015 410.012 149.079 418.222 196.278C426.662 244.79 404.37 299.917 365.813 330.501C329.152 359.58 275.533 336.074 229.767 345.76C191.357 353.889 158.801 392.371 120.841 382.346C82.6668 372.264 64.8099 329.723 43.377 296.536C23.179 265.26 3.1638 233.435 0.676411 196.278Z"
              fill="#F5FAFF"
            />
          </svg>
          <svg
            className="bulle-mobile-gauche"
            style={{ position: "absolute", top: "80%", left: "-10%" }}
            width="289"
            height="270"
            viewBox="0 0 289 270"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-26.9626 136.73C-28.618 73.4599 24.929 25.4969 85.4345 6.8233C142.975 -10.9352 204.749 6.40912 241.961 53.7328C284.373 107.67 309.403 183.947 267.331 238.15C227.609 289.324 152.989 268.396 91.7258 247.284C35.2021 227.804 -25.3996 196.468 -26.9626 136.73Z"
              fill="#F5FAFF"
            />
          </svg>
          <MobileMediumArticles>
            <NumbersContainer>
              <Number>{prismic.body[1].items[0].number.text}</Number>
              <Explanation>
                {prismic.body[1].items[0].number_infos.text}
              </Explanation>
              <MoreInfos>Wow !</MoreInfos>
            </NumbersContainer>
            <NumbersContainer>
              <Number>{prismic.body[1].items[1].number.text}</Number>
              <Explanation>
                {prismic.body[1].items[1].number_infos.text}
              </Explanation>
            </NumbersContainer>
            <NumbersContainer>
              <Number>{prismic.body[1].items[2].number.text}</Number>
              <Explanation>
                {prismic.body[1].items[2].number_infos.text}
              </Explanation>
            </NumbersContainer>
            <NumbersContainer>
              <Number>{prismic.body[1].items[3].number.text}</Number>
              <Explanation>
                {prismic.body[1].items[3].number_infos.text}
              </Explanation>
            </NumbersContainer>
            <NumbersContainer>
              <Number className="nowrap">
                {prismic.body[1].items[4].number.text}
              </Number>
              <Explanation>
                {prismic.body[1].items[4].number_infos.text}
              </Explanation>
            </NumbersContainer>
          </MobileMediumArticles>
          <MobileTextAndImages>
            <UpperBlock>
              <MobileImg1>
                <ImgParallax speed={3.9}>
                  <Img
                    loading="eager"
                    fluid={
                      prismic.body[1].primary.left_image_2.localFile
                        .childImageSharp.fluid
                    }
                    alt={prismic.body[1].primary.left_image_2.alt}
                  />
                </ImgParallax>
              </MobileImg1>
              <MobileImg2>
                <ImgParallax speed={2.5}>
                  <Img
                    loading="eager"
                    fluid={
                      prismic.body[1].primary.right_image_1.localFile
                        .childImageSharp.fluid
                    }
                    alt={prismic.body[1].primary.right_image_1.alt}
                  />
                </ImgParallax>
              </MobileImg2>
              <BlockText>
                <span>Manifesto</span>
                {prismic.body[1].primary.numbers_text1.text}
              </BlockText>
            </UpperBlock>
            <LowerBlock>
              <MobileImg3>
                <ImgParallax speed={2.1}>
                  <Img
                    loading="eager"
                    fluid={
                      prismic.body[1].primary.right_image_2.localFile
                        .childImageSharp.fluid
                    }
                    alt={prismic.body[1].primary.right_image_2.alt}
                  />
                </ImgParallax>
              </MobileImg3>
              <MobileImg4>
                <ImgParallax speed={1.4}>
                  <Img
                    loading="eager"
                    fluid={
                      prismic.body[1].primary.left_image_1.localFile
                        .childImageSharp.fluid
                    }
                    alt={prismic.body[1].primary.left_image_1.alt}
                  />
                </ImgParallax>
              </MobileImg4>
              <SmallText>
                {prismic.body[1].primary.numbers_text2.text}
              </SmallText>
            </LowerBlock>
          </MobileTextAndImages>
        </>
      )}
      <CatchPhrase>{prismic.body[1].primary.numbers_last.text}</CatchPhrase>
    </Container>
  )
}

export default Numbers
